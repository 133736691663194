// @ts-ignore
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import i18n from "@projectdiction/translations";
import PDHome from "./components/PDHome";

export default function Root(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <PDHome></PDHome>
    </I18nextProvider>
  );
}
