// @ts-ignore
import { Card } from "@projectdiction/common-react";
// @ts-ignore
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: "100%",
    "& > .p-card-body": {
      "& > .p-card-title, & > .p-card-subtitle": {
        textAlign: "center",
      },
      "& > .p-card-content": {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
});

function PDHome() {
  const classses = useStyles();

  const { t } = useTranslation();

  return (
    <Card
      title={t("titles.project_diction")}
      subTitle={t("subtitles.by_sahil")}
      className={classses.root}
    >
      {t("texts.welcome")}
    </Card>
  );
}

export default PDHome;
